import React, { forwardRef } from "react";
import { BSDiv } from "../types";
import { SR } from "./SR";

export interface SpinnerProps extends BSDiv {
  variant?: "border" | "grow";
  size?: "small" | "sm";
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (
    { utilities = "", variant = "border", size, color, children, ...props },
    ref
  ) => {
    if (color) {
      utilities += ` text-${color}`;
    }
    if (size === "small" || size == "sm") {
      utilities += ` spinner-${variant}-sm`;
    }
    return (
      <div {...props} className={`spinner-${variant} ${utilities}`} ref={ref}>
        <SR>Loading...</SR>
      </div>
    );
  }
);
