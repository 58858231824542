import { SizesAbbr } from "../types";

const sizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const useSizeToBreakpoint = (size: undefined | SizesAbbr | false) => {
  if (!size) return;
  return sizes[size];
};
