import React, {
  useState,
  ReactNode,
  forwardRef,
  useRef,
  useEffect,
  MutableRefObject,
} from "react";
import {
  BSDiv,
  BSAnchor,
  BSMultiElement,
  Sizes,
  SizesAbbr,
  BSSpan,
} from "../types";
import { Collapse } from "./Collapse";
import {
  useSizeToAbbr,
  useSizeToBreakpoint,
  useWindowSize,
  isFunction,
} from "../hooks";

interface NavbarRenderProps {
  set: (v: boolean) => void;
  visible: boolean;
  ref: MutableRefObject<HTMLDivElement | null>;
  utilities: string;
}

export interface NavbarProps extends BSDiv {
  color?: "light" | "dark";
  container?: "sm" | "md" | "lg" | "xl" | "fluid" | boolean;
  expand?: Sizes | SizesAbbr | false;
  children?: ReactNode | ((p: NavbarRenderProps) => ReactNode);
  brand?: ReactNode;
}

export const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  (
    {
      children,
      utilities = "",
      color = "light",
      expand = "sm",
      container,
      brand,
      ...props
    },
    ref
  ) => {
    const size = useWindowSize();
    const expandAbbr = useSizeToAbbr(expand);
    const breakpoint = useSizeToBreakpoint(expandAbbr);
    const navRef = useRef<HTMLDivElement>(null);
    const [visible, set] = useState(!!expand);

    useEffect(() => {
      if (
        navRef.current &&
        window.getComputedStyle(navRef.current).display === "none"
      ) {
        set(false);
      } else if (breakpoint) {
        set(!!(size && size.width && size.width > breakpoint));
      }
    }, [size.width]);

    if (color) {
      utilities += ` navbar-${color}`;
    }

    if (expandAbbr) {
      utilities += ` navbar-expand-${expandAbbr}`;
    }

    const childProps = {
      visible,
      set,
      ref: navRef,
      utilities: "navbar-collapse",
    };

    let navChildren =
      children && isFunction(children) ? (
        children(childProps)
      ) : (
        <SimpleNavBar {...childProps} brand={brand}>
          {children}
        </SimpleNavBar>
      );

    if (container) {
      navChildren = (
        <div
          className={`container${container !== true ? "-" + container : ""}`}
        >
          {navChildren}
        </div>
      );
    }

    return (
      <nav
        {...props}
        className={`navbar ${utilities}`}
        role="navigation"
        ref={ref}
      >
        {navChildren}
      </nav>
    );
  }
);

interface SimpleNavBarProps extends NavbarRenderProps {
  brand?: ReactNode;
  children?: ReactNode;
}

export const SimpleNavBar = forwardRef<HTMLDivElement, SimpleNavBarProps>(
  ({ children, set, brand, ...p }, ref) => (
    <>
      {typeof brand === "string" ? (
        <NavbarBrand href="#">{brand}</NavbarBrand>
      ) : (
        brand
      )}
      <NavbarToggler onClick={() => set(!p.visible)} />
      <Collapse {...p} ref={ref}>
        {children}
      </Collapse>
    </>
  )
);

export const NavbarBrand = forwardRef<HTMLAnchorElement, BSAnchor>(
  ({ children, utilities = "", ...props }, ref) => (
    <a {...props} className={`navbar-brand ${utilities}`} ref={ref}>
      {children}
    </a>
  )
);

export const NavbarToggler = forwardRef<HTMLButtonElement, BSMultiElement>(
  ({ children, utilities = "", ...props }, ref) => (
    <button
      {...props}
      className={`navbar-toggler ${utilities}`}
      type="button"
      aria-label="Toggle navigation"
      ref={ref}
    >
      <span className="navbar-toggler-icon"></span>
    </button>
  )
);

export const NavbarNav = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div
        {...props}
        className={`navbar-nav ${utilities}`}
        role="navigation"
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export const NavbarText = forwardRef<HTMLSpanElement, BSSpan>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <span
        {...props}
        className={`navbar-text ${utilities}`}
        role="navigation"
        ref={ref}
      >
        {children}
      </span>
    );
  }
);
