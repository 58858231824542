import React, { ReactNode, FunctionComponent, forwardRef } from "react";
import { Manager, Reference, Popper, PopperChildrenProps } from "react-popper";
import { AnimatePresence, motion } from "framer-motion";
import { BSMotionDiv, BSMultiElement, Triggers } from "../types";
import { useSharedState } from "../hooks";

export interface PopoverPopperProps extends Omit<BSMotionDiv, "title"> {
  placement?: "top" | "right" | "bottom" | "left";
  title?: ReactNode;
  visible?: boolean;
}

export const PopoverPopper: FunctionComponent<PopoverPopperProps> = ({
  placement = "right",
  visible,
  ...popoverProps
}) => (
  <AnimatePresence>
    {visible && (
      <Popper placement={placement}>
        {(p) => (
          <PopoverElement {...popoverProps} {...p} placement={placement} />
        )}
      </Popper>
    )}
  </AnimatePresence>
);

interface PopoverElementProps
  extends PopoverPopperProps,
    Omit<PopperChildrenProps, "placement" | "style"> {}

export const PopoverElement = forwardRef<HTMLDivElement, PopoverElementProps>(
  (
    {
      style,
      placement,
      arrowProps,
      utilities = "",
      title,
      children,
      ...divProps
    },
    ref
  ) => (
    <motion.div
      ref={ref}
      style={style}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      data-placement={placement}
      role="tooltip"
      className={`popover bs-popover-${placement} ${utilities}`}
      {...divProps}
    >
      <div className="popover-arrow" {...arrowProps}></div>
      {title && <h3 className="popover-header">{title}</h3>}
      <div className="popover-body">{children}</div>
    </motion.div>
  )
);

export { Manager, Reference };

export interface PopoverProps extends Omit<PopoverPopperProps, "visible"> {
  content: ReactNode;
  children: (props: BSMultiElement) => JSX.Element;
  trigger?: Triggers | Triggers[];
  visible?: boolean;
  onToggle?: (visible: boolean) => void;
}

export const Popover: FunctionComponent<PopoverProps> = ({
  trigger = ["click"],
  children,
  content,
  onToggle,
  ...props
}) => {
  const [visible, set] = useSharedState(props.visible, onToggle);

  const triggers = {
    hover: {
      onMouseEnter: () => set(true),
      onMouseLeave: () => set(false),
    },
    focus: {
      onFocus: () => set(true),
      onBlur: () => set(false),
    },
    click: {
      onClick: () => set(!visible),
    },
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          children({
            ref,
            ...(typeof trigger === "string" ? [trigger] : trigger)
              .map((t) => triggers[t])
              .reduce((obj: any, item: any) => {
                for (let i in item) obj[i] = item[i];
                return obj;
              }, {} as BSMultiElement),
          })
        }
      </Reference>
      <PopoverPopper {...props} visible={visible}>
        {content}
      </PopoverPopper>
    </Manager>
  );
};
