import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BSDiv } from "../types";
import { Close } from "./Button";

export type ToasterAddHandler = (props: ToastProps) => void;
export type ToasterRemoveHandler = (key: number) => void;

export interface Toaster extends BSDiv {
  duration?: number;
  children: (add: ToasterAddHandler, remove: ToasterRemoveHandler) => ReactNode;
}

export const Toaster = forwardRef<HTMLDivElement, Toaster>(
  ({ utilities = "", children, duration = 5 }, ref) => {
    const index = useRef(0);
    const [items, setItems] = useState<ToastProps[]>([]);

    useEffect(
      () =>
        void children(
          (props) =>
            setItems((state) => [...state, { key: index.current++, ...props }]),
          (key) => setItems((state) => state.filter((i) => i.key !== key))
        ),
      []
    );

    return (
      <div
        className={utilities}
        style={{
          position: "fixed",
          bottom: 15,
          right: 15,
          width: "350px",
          zIndex: 1000,
        }}
        ref={ref}
      >
        <AnimatePresence initial={false}>
          {items.map((p) => {
            return (
              <Toast
                {...p}
                duration={p.duration || duration}
                onDismiss={() =>
                  setItems((state) => state.filter((i) => i.key !== p.key))
                }
              />
            );
          })}
        </AnimatePresence>
      </div>
    );
  }
);

export interface ToastProps extends Omit<BSDiv, "title"> {
  key?: number;
  title?: ReactNode;
  time?: ReactNode;
  body?: ReactNode;
  dismissable?: boolean;
  icon?: ReactNode;
  duration?: number;
  onDismiss?: () => void;
}

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  (
    {
      utilities = "",
      dismissable = true,
      title,
      time,
      onDismiss,
      children,
      body,
      icon,
      duration = 5,
      key,
    },
    ref
  ) => {
    useEffect(() => {
      onDismiss && setTimeout(onDismiss, duration * 1000);
    }, [onDismiss]);

    return (
      <motion.div
        ref={ref}
        className={`toast show ${utilities}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: 1,
          height: "auto",
          transition: { ease: "easeOut" },
        }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
        key={key}
      >
        {(icon || title || time || dismissable) && (
          <ToastHeader>
            {icon}
            {title && <strong className="mr-auto">{title}</strong>}
            {time && <small className="text-muted">{time}</small>}
            {dismissable && <Close onClick={onDismiss} utilities="ml-2 mb-1" />}
          </ToastHeader>
        )}
        <ToastBody>
          {children}
          {body}
        </ToastBody>
        <motion.div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "auto",
            height: 2,
            backgroundImage: "linear-gradient(130deg, #00b4e6, #00f0e0)",
          }}
          animate={{
            right: 0,
            transition: { ease: "easeOut", duration: duration },
          }}
        />
      </motion.div>
    );
  }
);

export const ToastHeader = forwardRef<HTMLDivElement, BSDiv>(
  ({ utilities = "", children, ...props }, ref) => {
    return (
      <div {...props} className={`toast-header ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export const ToastBody = forwardRef<HTMLDivElement, BSDiv>(
  ({ utilities = "", children, ...props }, ref) => {
    return (
      <div {...props} className={`toast-body ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);
