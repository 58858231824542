import React, { forwardRef } from "react";
import { BSList, BSAnchor } from "../types";

export const Breadcrumb = forwardRef<HTMLDivElement, BSList>(
  ({ children, utilities = "", ...props }, ref) => (
    <nav aria-label="breadcrumb" ref={ref}>
      <ol className={`breadcrumb ${utilities}`} {...props}>
        {children}
      </ol>
    </nav>
  )
);

export interface BreadcrumbItemProps extends BSAnchor {
  active?: boolean;
}

export const BreadcrumbItem = forwardRef<HTMLLIElement, BreadcrumbItemProps>(
  ({ active, utilities = "", children, ...props }, ref) => {
    const listItemProps: React.HTMLAttributes<HTMLLIElement> = {
      className: `breadcrumb-item ${utilities}`,
    };

    if (active) {
      listItemProps.className += " active";
      listItemProps["aria-current"] = "page";
    }

    return (
      <li {...listItemProps} ref={ref}>
        {props.href ? <a {...props}>{children}</a> : children}
      </li>
    );
  }
);
