import React, { forwardRef } from "react";
import { BSDiv, BSAnchor } from "../types";
import { useSizeToAbbr } from "../hooks";

export interface PaginationProps extends BSDiv {
  size?: "small" | "large" | "sm" | "lg";
}

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  ({ children, utilities = "", size, ...props }, ref) => {
    const abbrSize = useSizeToAbbr(size);
    if (abbrSize) utilities += ` pagination-${abbrSize}`;

    return (
      <nav {...props} ref={ref}>
        <ul className={`pagination ${utilities}`}>{children}</ul>
      </nav>
    );
  }
);

export interface PaginationItemProps extends BSAnchor {
  active?: boolean;
  disabled?: boolean;
}

export const PaginationItem = forwardRef<
  HTMLAnchorElement,
  PaginationItemProps
>(
  (
    { children, utilities = "", active, disabled, href = "#", ...props },
    ref
  ) => {
    if (disabled) {
      props.tabIndex = -1;
      props["aria-disabled"] = "true";
    }

    return (
      <li
        className={`page-item ${active ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <a
          className={`page-link ${utilities}`}
          href={href}
          {...props}
          ref={ref}
        >
          {children}
        </a>
      </li>
    );
  }
);
