import React, { FunctionComponent, forwardRef } from "react";
import { Manager, Reference, Popper, PopperChildrenProps } from "react-popper";
import { AnimatePresence, motion } from "framer-motion";
import { BSMotionDiv, BSMultiElement, Triggers } from "../types";
import { useSharedState } from "../hooks";

export interface TooltipPopperProps extends BSMotionDiv {
  placement?: "top" | "right" | "bottom" | "left";
  visible?: boolean;
}

export const TooltipPopper: FunctionComponent<TooltipPopperProps> = ({
  placement = "top",
  visible = false,
  ...tooltipProps
}) => (
  <AnimatePresence>
    {visible && (
      <Popper placement={placement}>
        {(p) => (
          <TooltipElement {...tooltipProps} {...p} placement={placement} />
        )}
      </Popper>
    )}
  </AnimatePresence>
);

export { Manager as TooltipManager, Reference as TooltipReference };

interface TooltipElementProps
  extends TooltipPopperProps,
    Omit<PopperChildrenProps, "placement" | "style"> {}

export const TooltipElement = forwardRef<HTMLDivElement, TooltipElementProps>(
  (
    { style, placement, arrowProps, utilities = "", children, ...divProps },
    ref
  ) => (
    <motion.div
      ref={ref}
      style={style}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      data-placement={placement}
      role="tooltip"
      className={`tooltip bs-tooltip-${placement} ${utilities}`}
      {...divProps}
    >
      <div className="tooltip-arrow" {...arrowProps}></div>
      <div className="tooltip-inner">{children}</div>
    </motion.div>
  )
);

export interface TooltipProps extends Omit<TooltipPopperProps, "visible"> {
  content: JSX.Element | string;
  children: (props: BSMultiElement) => JSX.Element;
  trigger?: Triggers | Triggers[];
  visible?: boolean;
  onToggle?: (visible: boolean) => void;
}

export const Tooltip: FunctionComponent<TooltipProps> = ({
  trigger = ["hover", "focus"],
  children,
  content,
  onToggle,
  ...props
}) => {
  const [visible, set] = useSharedState(props.visible, onToggle);

  const triggers = {
    hover: {
      onMouseEnter: () => set(true),
      onMouseLeave: () => set(false),
    },
    focus: {
      onFocus: () => set(true),
      onBlur: () => set(false),
    },
    click: {
      onClick: () => set(!visible),
    },
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          children({
            ref,
            ...(typeof trigger === "string" ? [trigger] : trigger)
              .map((t) => triggers[t])
              .reduce((obj: any, item: any) => {
                for (let i in item) obj[i] = item[i];
                return obj;
              }, {} as BSMultiElement),
          })
        }
      </Reference>
      <TooltipPopper {...props} visible={visible}>
        {content}
      </TooltipPopper>
    </Manager>
  );
};
