import React, { forwardRef } from "react";
import { BSDiv, BSMultiElement } from "../types";

export interface ListGroupProps extends BSDiv {
  layout?:
    | "flush"
    | "horizontal"
    | "horizontal-sm"
    | "horizontal-md"
    | "horizontal-lg"
    | "horizontal-xls";
}

export const ListGroup = forwardRef<HTMLDivElement, ListGroupProps>(
  ({ children, layout, utilities = "", ...props }, ref) => {
    if (layout) {
      utilities += ` list-group-${layout}`;
    }

    return (
      <div {...props} className={`list-group ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export interface ListGroupItemProps extends BSMultiElement {
  active?: boolean;
  disabled?: boolean;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
}

export const ListGroupItem = forwardRef<HTMLButtonElement, ListGroupItemProps>(
  (
    {
      children,
      utilities = "",
      active,
      disabled,
      color,
      href,
      type,
      ...itemProps
    },
    ref
  ) => {
    const props: any = {
      ...itemProps,
      ref,
    };

    if (active) {
      utilities += " active";
    }
    if (disabled) {
      utilities += " disabled";
    }
    if (type === "button" || href) {
      utilities += " list-group-item-action";
    }
    if (color) {
      utilities += ` list-group-item-${color}`;
    }

    props.className = `list-group-item ${utilities}`;

    return type === "button" ? (
      <button {...props} disabled={disabled}>
        {children}
      </button>
    ) : href ? (
      <a {...props} href={href}>
        {children}
      </a>
    ) : (
      <div {...props}>{children}</div>
    );
  }
);
