import { Sizes, SizesAbbr } from "../types";

const sizes = {
  small: "sm",
  medium: "md",
  large: "lg",
  xlarge: "xl",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

export const useSizeToAbbr = (
  size: undefined | Sizes | SizesAbbr | false
): SizesAbbr | undefined => {
  if (!size) return;
  return sizes[size] as SizesAbbr;
};
