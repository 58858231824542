import React, { forwardRef, useEffect } from "react";
import { BSDiv, BSMotionDiv, BSHeading } from "../types";
import { AnimatePresence, motion } from "framer-motion";
import { Close } from "./Button";

export interface ModalProps extends BSDiv {
  alignment?: "top" | "center";
  transition?: "spring" | "tween";
  visible?: boolean;
  scrollable?: boolean;
  onDismiss?: () => void;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      utilities = "",
      visible,
      children,
      transition = "tween",
      onDismiss,
      scrollable = false,
      alignment = "top",
    },
    ref
  ) => {
    useEffect(() => {
      if (visible) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
      return () => document.body.classList.remove("modal-open");
    }, [visible]);

    return (
      <AnimatePresence>
        {visible && (
          <ModalBackdrop
            key="backdrop"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 0.5 },
            }}
            utilities="show"
          />
        )}
        {visible && (
          <motion.div
            ref={ref}
            onClick={onDismiss}
            key="modal"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              hidden: {
                opacity: 0,
                y: transition === "tween" ? -50 : -150,
                transition: { ease: "easeOut", duration: 0.3 },
              },
              visible: {
                opacity: 1,
                y: 0,
                transition:
                  transition === "tween"
                    ? { ease: "easeOut", duration: 0.3 }
                    : {},
              },
            }}
            className={`modal show ${utilities}`}
            tabIndex={-1}
            role="dialog"
            style={{ display: "block" }}
          >
            <ModalDialog
              onClick={(e) => e.stopPropagation()}
              utilities="show"
              scrollable={scrollable}
              alignment={alignment}
            >
              <ModalContent>{children}</ModalContent>
            </ModalDialog>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

export interface ModalDialogProps extends BSDiv {
  scrollable?: boolean;
  alignment?: "top" | "center";
}

export const ModalDialog = forwardRef<HTMLDivElement, ModalDialogProps>(
  ({ utilities = "", children, scrollable, alignment, ...props }, ref) => (
    <div
      {...props}
      className={`modal-dialog 
    ${scrollable ? "modal-dialog-scrollable" : ""} 
    ${alignment === "center" ? "modal-dialog-centered" : ""} 
    ${utilities}`}
      ref={ref}
    >
      {children}
    </div>
  )
);

export const ModalContent = forwardRef<HTMLDivElement, BSDiv>(
  ({ utilities = "", children, ...props }, ref) => (
    <div {...props} className={`modal-content ${utilities}`} ref={ref}>
      {children}
    </div>
  )
);

export interface ModalHeaderProps extends BSDiv {
  onDismiss?: () => void;
}

export const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(
  ({ utilities = "", children, onDismiss, ...props }, ref) => (
    <div {...props} className={`modal-header ${utilities}`} ref={ref}>
      {children}
      {onDismiss && <Close onClick={onDismiss} />}
    </div>
  )
);

export const ModalTitle = forwardRef<HTMLHeadingElement, BSHeading>(
  ({ utilities = "", children, ...props }, ref) => (
    <h5 {...props} className={`modal-title ${utilities}`} ref={ref}>
      {children}
    </h5>
  )
);

export const ModalBody = forwardRef<HTMLDivElement, BSDiv>(
  ({ utilities = "", children, ...props }, ref) => (
    <div {...props} className={`modal-body ${utilities}`} ref={ref}>
      {children}
    </div>
  )
);

export const ModalFooter = forwardRef<HTMLDivElement, BSDiv>(
  ({ utilities = "", children, ...props }, ref) => (
    <div {...props} className={`modal-footer ${utilities}`} ref={ref}>
      {children}
    </div>
  )
);

export const ModalBackdrop = forwardRef<HTMLDivElement, BSMotionDiv>(
  ({ utilities = "", children, ...props }, ref) => (
    <motion.div {...props} className={`modal-backdrop ${utilities}`} ref={ref}>
      {children}
    </motion.div>
  )
);
