import React, { forwardRef } from "react";
import { BSMultiElement, BSDiv } from "../types";

export interface ButtonProps extends BSMultiElement {
  size?: "large" | "small" | "sm" | "lg";
  variant?: "outline" | "close";
  layout?: "block";
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";
  active?: boolean;
  disabled?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      size,
      disabled,
      layout,
      variant,
      color = "primary",
      utilities = "",
      active,
      ...props
    },
    ref
  ) => {
    const additionalProps: any = {};
    additionalProps.ref = ref;

    let className =
      variant !== "close"
        ? `btn btn-${variant === "outline" ? "outline-" : ""}${color}`
        : "close";
    if (size === "large" || size === "lg") className += " btn-lg";
    if (size === "small" || size === "sm") className += " btn-sm";
    if (layout === "block") className += " btn-block";

    if (active) {
      className += " active";
      additionalProps["aria-pressed"] = true;
    }

    if (utilities) className += ` ${utilities}`;

    if (disabled) {
      additionalProps.disabled = true;
    }

    if (props.href) {
      if (disabled) {
        className += " disabled";
        additionalProps.tabIndex = -1;
        additionalProps["aria-disabled"] = true;
      }

      return (
        <a {...props} {...additionalProps} className={className} role="button">
          {children}
        </a>
      );
    }

    if (props.value) {
      return (
        <input
          {...{ ...props, size: undefined }}
          {...additionalProps}
          className={className}
        />
      );
    }

    return (
      <button
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        {...(additionalProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        className={className}
      >
        {children}
      </button>
    );
  }
);

export interface ButtonGroupProps extends BSDiv {
  size?: "small" | "large" | "sm" | "lg";
  orientation?: "vertical" | "horizontal";
}

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (
    { children, size, orientation = "horizontal", utilities = "", ...props },
    ref
  ) => {
    let className = "btn-group";
    if (orientation === "vertical") className += "-vertical";
    if (size === "small" || size === "sm") className += " btn-group-sm";
    if (size === "large" || size === "lg") className += " btn-group-lg";
    if (utilities) className += ` ${utilities}`;

    return (
      <div {...props} className={className} role="group" ref={ref}>
        {children}
      </div>
    );
  }
);

export const ButtonToolbar = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => (
    <div
      {...props}
      className={`btn-toolbar ${utilities}`}
      role="toolbar"
      ref={ref}
    >
      {children}
    </div>
  )
);

export const Close = forwardRef<HTMLButtonElement, BSMultiElement>(
  ({ children, ...props }, ref) => (
    <Button {...props} variant="close" aria-label="close" ref={ref}>
      <span aria-hidden="true">&times;</span>
    </Button>
  )
);
