import React, { forwardRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { BSMotionDiv, BSHeading, BSAnchor } from "../types";
import { useSharedState } from "../hooks/useSharedState";
import { Close } from "./Button";

export interface AlertProps extends BSMotionDiv {
  visible?: boolean;
  dismissable?: boolean;
  onDismiss?: (visible: boolean) => void;
  onDismissed?: () => void;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      color = "primary",
      dismissable,
      children,
      onDismiss,
      onDismissed,
      visible = true,
      utilities = "",
      ...divProps
    },
    ref
  ) => {
    const [_visible, set] = useSharedState(visible, onDismiss);
    const isDismissable = dismissable || onDismiss;

    if (isDismissable) {
      utilities += " alert-dismissible";
    }

    return (
      <AnimatePresence initial={false}>
        {_visible && (
          <motion.div
            {...divProps}
            ref={ref}
            initial={false}
            exit={{ opacity: 0 }}
            onAnimationComplete={onDismissed}
            className={`alert alert-${color} ${utilities}`}
            role="alert"
          >
            {children}
            {isDismissable && <Close onClick={() => set(false)} />}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
);

export const AlertHeading = forwardRef<HTMLHeadingElement, BSHeading>(
  ({ children, utilities = "", ...props }, ref) => (
    <h4 ref={ref} {...props} className={`alert-heading ${utilities}`}>
      {children}
    </h4>
  )
);

export const AlertLink = forwardRef<HTMLAnchorElement, BSAnchor>(
  ({ children, utilities = "", ...props }, ref) => (
    <a {...props} ref={ref} className={`alert-link ${utilities}`}>
      {children}
    </a>
  )
);
