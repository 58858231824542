import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { motion, useSpring, useMotionValue } from "framer-motion";
import { BSDiv } from "../types";
import { SR } from "./SR";
import { useWindowSize, useMeasure } from "../hooks";

export interface CarouselProps extends BSDiv {
  indicators?: boolean;
  controls?: boolean;
  index?: number;
  transition?: "slide";
  useGestures?: boolean;
  interval?: number | boolean;
}

export const Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  (
    {
      utilities = "",
      indicators = true,
      controls = true,
      index = 0,
      transition = "slide",
      useGestures = true,
      interval = 5000,
      ...props
    },
    ref
  ) => {
    const size = useWindowSize();
    const timeout = useRef(0);
    const [measureRef, { width }] = useMeasure();
    const x = useSpring(0, { stiffness: 100, damping: 200 });
    const trailingx = useMotionValue(0);
    const [page, setPage] = useState(0);
    const children: ReactElement[] = React.Children.toArray(
      props.children
    ) as ReactElement[];

    useEffect(() => {
      // fix pos on window resize
      x.set(-page * width);
    }, [size.width]);

    useEffect(() => {
      function updateFrames() {
        const xval = x.get();
        const position = (xval * -1) / width;
        const index =
          xval < trailingx.get() ? Math.ceil(position) : Math.floor(position);

        if (page !== index) {
          setPage(index);
        }

        trailingx.set(xval);
      }

      const unsubscribeX = x.onChange(updateFrames);

      return () => {
        unsubscribeX();
      };
    }, [page, width]);

    const startTimer = useCallback(() => {
      if (typeof interval === "number") {
        timeout.current = window.setTimeout(() => {
          x.set(-page * width - width);
        }, interval);
      }
    }, [timeout, page, width]);

    useEffect(() => {
      clearTimeout(timeout.current);
      startTimer();
    }, [timeout, page, width]);

    return (
      <div className={`carousel ${utilities}`} ref={ref}>
        {indicators && (
          <ol className="carousel-indicators">
            {children.map((_item, i) => (
              <li
                key={i}
                className={i === wrap(0, children.length, page) ? "active" : ""}
                onClick={() => {
                  const wrappedPage = wrap(0, children.length, page);
                  if (i === wrappedPage) return;
                  const offset = (i - wrappedPage) * width;
                  x.set(-page * width - offset);
                }}
              />
            ))}
          </ol>
        )}
        <div className="carousel-inner" ref={measureRef}>
          <motion.div
            key={index}
            drag="x"
            style={{ x }}
            dragMomentum={false}
            onDragStart={() => clearTimeout(timeout.current)}
            onDragEnd={({}, { offset, velocity }) => {
              const swipe = Math.abs(swipePower(offset.x, velocity.x));
              if (swipe > swipeConfidenceThreshold) {
                x.set(-page * width);
              } else {
                // ok then, go to nearest page
                const index = Math.round((x.get() * -1) / width);
                x.set(-index * width);
              }
            }}
          >
            {children.map((item, index) => {
              const wrappedIndex = wrap(0, children.length, page);

              let translation = 500;

              if (index === wrappedIndex) {
                translation = 0;
              }
              if (index === wrap(0, children.length, page + 1)) {
                translation = 100;
              }
              if (index === wrap(0, children.length, page - 1)) {
                translation = -100;
              }

              translation += page * 100;

              return React.cloneElement(item, {
                key: index,
                style: {
                  transform: `translateX(${translation}%)`,
                  transition: "none",
                },
              });
            })}
          </motion.div>
        </div>
        {controls && (
          <>
            <a
              className="carousel-control-prev"
              href="#"
              role="button"
              data-slide="prev"
              onClick={(e) => {
                e.preventDefault();
                x.set(-page * width + width);
              }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <SR>Previous</SR>
            </a>
            <a
              className="carousel-control-next"
              href="#"
              role="button"
              data-slide="next"
              onClick={(e) => {
                e.preventDefault();
                x.set(-page * width - width);
              }}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <SR>Next</SR>
            </a>
          </>
        )}
      </div>
    );
  }
);

export const CarouselItem = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div {...props} className={`carousel-item active ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const CarouselCaption = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div
        {...props}
        style={{ transition: "none" }}
        className={`carousel-caption ${utilities}`}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

// Wrap Code adapted from @popmotion
type RangeFunction = (min: number, max: number, v: number) => any;
const curryRange = (func: RangeFunction) => (
  min: number,
  max: number,
  v?: number
) => (v !== undefined ? func(min, max, v) : (cv: number) => func(min, max, cv));
const wrapFn = (min: number, max: number, v: number) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};
const wrap = curryRange(wrapFn);
