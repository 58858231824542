import React, { ReactNode, forwardRef, useEffect, useRef } from "react";
import {
  BSDiv,
  BSHeading,
  BSSpan,
  BSAnchor,
  Triggers,
  BSMultiElement,
} from "../types";
import { Button, ButtonProps } from "./Button";
import { SR } from "./SR";
import { useSharedState, useForkedRef } from "../hooks";

interface DropdownProps extends BSDiv {
  alignment?: "left" | "right";
  direction?: "down" | "up" | "left" | "right";
  variant?: "dropdown" | "btn-group" | "nav-item";
  trigger?: Triggers | Triggers[];
  action?: ReactNode;
  toggle: string | ((props: BSMultiElement) => ReactNode);
  visible?: boolean;
  onToggle?: (visible: boolean) => void;
}

let counter = 0;

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      alignment = "left",
      direction = "down",
      variant = "dropdown",
      utilities = "",
      trigger = "click",
      onToggle,
      children,
      action,
      toggle,
      ...props
    },
    forwardedRef
  ) => {
    const dropdownRef = useRef(null);
    const ref = useForkedRef(forwardedRef, dropdownRef);

    const [visible, set] = useSharedState(props.visible, onToggle);
    delete props.visible;

    useEffect(() => {
      window.addEventListener("keyup", handleKeyup);

      return () => {
        window.removeEventListener("keyup", handleKeyup);
      };
    });

    const triggers = {
      hover: {
        onMouseEnter: () => set(true),
      },
      focus: {
        onFocus: () => set(true),
        onBlur: () => set(false),
      },
      click: {
        onClick: () => set(!visible),
      },
    };

    if (action) {
      // actions can only exist in btn-groups
      variant = "btn-group";
    }

    const id = `bs-dd-${counter++}`;
    const dropDirection = direction === "down" ? "" : `drop${direction}`;
    const trig = typeof trigger === "string" ? [trigger] : trigger;

    const toggleProps = {
      id,
      "aria-haspopup": true,
      "aria-expanded": visible,
      ...trig
        .map((t) => triggers[t])
        .reduce((obj: any, item: any) => {
          for (let i in item) obj[i] = item[i];
          return obj;
        }, {} as BSMultiElement),
    };

    const handleKeyup = (e: Event) => {
      if (
        !((dropdownRef.current as unknown) as HTMLElement).contains(
          e.target as HTMLElement
        )
      ) {
        set(false);
      }
    };

    const toggler =
      typeof toggle === "string"
        ? (p: BSMultiElement) =>
            variant === "nav-item" ? (
              <NavLinkToggle {...p}>{toggle}</NavLinkToggle>
            ) : (
              <DropdownToggle {...p}>{toggle}</DropdownToggle>
            )
        : toggle;

    return (
      <div
        ref={ref}
        {...props}
        className={`dropdown ${variant} ${dropDirection} ${utilities}`}
      >
        {action}
        {toggler(toggleProps)}
        {visible && (
          <div
            onClick={() => set(false)}
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "fixed",
              zIndex: 1000,
            }}
          ></div>
        )}
        <DropdownMenu
          alignment={alignment}
          visible={visible}
          aria-labelledby={id}
          onClick={() => set(!visible)}
        >
          {children}
        </DropdownMenu>
      </div>
    );
  }
);

interface DropdownMenuProps extends BSDiv {
  visible?: boolean;
  alignment?: "left" | "right";
}

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    { children, visible, alignment = "left", utilities = "", ...props },
    ref
  ) => (
    <div
      {...props}
      ref={ref}
      className={`dropdown-menu ${visible ? "show" : ""} ${
        alignment === "right" ? "dropdown-menu-right" : ""
      } ${utilities}`}
    >
      {children}
    </div>
  )
);

export const DropdownDivider = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, ...props }, ref) => (
    <div {...props} className="dropdown-divider" ref={ref}>
      {children}
    </div>
  )
);

export const DropdownHeader = forwardRef<HTMLHeadingElement, BSHeading>(
  ({ children, ...props }, ref) => (
    <h6 {...props} className="dropdown-header" ref={ref}>
      {children}
    </h6>
  )
);

export const DropdownItemText = forwardRef<HTMLSpanElement, BSSpan>(
  ({ children, ...props }, ref) => (
    <span {...props} className="dropdown-item-text" ref={ref}>
      {children}
    </span>
  )
);

export const DropdownItem = forwardRef<HTMLAnchorElement, BSAnchor>(
  ({ children, ...props }, ref) => (
    <a {...props} className="dropdown-item" ref={ref}>
      {children}
    </a>
  )
);

export interface DropdownToggleProps extends ButtonProps {
  split?: boolean;
}

export const DropdownToggle = forwardRef<
  HTMLButtonElement,
  DropdownToggleProps
>(({ children, split, utilities, ...props }, ref) => (
  <Button
    {...props}
    utilities={`dropdown-toggle ${
      split ? "dropdown-toggle-split" : ""
    } ${utilities}`}
    ref={ref}
  >
    {children}
    {split && <SR>Toggle Dropdown</SR>}
  </Button>
));

export const NavLinkToggle = forwardRef<HTMLAnchorElement, DropdownToggleProps>(
  ({ children, utilities, onClick, ...props }, ref) => (
    <a
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      href="#"
      className="nav-link dropdown-toggle"
      ref={ref}
    >
      {children}
    </a>
  )
);
