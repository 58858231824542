import React, { forwardRef, ReactElement } from "react";
import { BSDiv, BSAnchor } from "../types";
import { motion, AnimatePresence } from "framer-motion";

export interface NavProps extends BSDiv {
  variant?: "tabs" | "pills";
  layout?: "fill" | "justified";
}

export const Nav = forwardRef<HTMLDivElement, NavProps>(
  ({ children, utilities = "", variant, layout, ...props }, ref) => {
    if (variant) {
      utilities += ` nav-${variant}`;
    }
    if (layout) {
      utilities += ` nav-${layout}`;
    }
    return (
      <div
        {...props}
        className={`nav ${utilities}`}
        role="navigation"
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export interface NavLinkProps extends BSAnchor {
  disabled?: boolean;
  active?: boolean;
}

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ children, disabled, utilities = "", active, ...props }, ref) => {
    if (active) {
      utilities += " active";
    }

    if (disabled) {
      utilities += " disabled";
      props.tabIndex = -1;
      props["aria-disabled"] = true;
    }

    return (
      <a {...props} className={`nav-item nav-link ${utilities}`} ref={ref}>
        {children}
      </a>
    );
  }
);

export interface TabContentProps extends BSDiv {
  index?: number;
  onTab?: () => void;
}

export const TabContent = forwardRef<HTMLDivElement, TabContentProps>(
  ({ index = 0, utilities = "", onTab, ...props }, ref) => {
    const children: ReactElement[] = React.Children.toArray(
      props.children
    ) as ReactElement[];

    return (
      <div
        {...props}
        className={`tab-content ${utilities}`}
        role="navigation"
        ref={ref}
      >
        <AnimatePresence
          initial={false}
          exitBeforeEnter={true}
          onExitComplete={onTab}
        >
          <motion.div
            key={index}
            variants={{
              enter: {
                opacity: 0,
              },
              center: {
                opacity: 1,
              },
              exit: {
                opacity: 0,
              },
            }}
            initial="enter"
            animate="center"
            exit="exit"
          >
            {React.cloneElement(children[index], {
              utilities: "active",
            })}
          </motion.div>
        </AnimatePresence>
      </div>
    );
  }
);

export const TabPane = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div
        {...props}
        className={`tab-pane ${utilities} active`}
        role="tabpanel"
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
