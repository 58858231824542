import React, { forwardRef } from "react";
import { BSDiv, BSHeading, BSParagraph, BSAnchor, BSImg } from "../types";

export const Card = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div {...props} className={`card ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export const CardHeader = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div {...props} className={`card-header ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export const CardFooter = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div {...props} className={`card-footer ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export interface CardBodyProps extends BSDiv {
  orientation?: "overlay";
}

export const CardBody = forwardRef<HTMLDivElement, CardBodyProps>(
  ({ children, utilities = "", orientation, ...props }, ref) => {
    const className =
      orientation === "overlay" ? "card-img-overlay" : "card-body";
    return (
      <div {...props} className={`${className} ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export const CardTitle = forwardRef<HTMLHeadingElement, BSHeading>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <h5 {...props} className={`card-title ${utilities}`} ref={ref}>
        {children}
      </h5>
    );
  }
);

export const CardSubtitle = forwardRef<HTMLHeadingElement, BSHeading>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <h6 {...props} className={`card-subtitle ${utilities}`} ref={ref}>
        {children}
      </h6>
    );
  }
);

export const CardText = forwardRef<HTMLParagraphElement, BSParagraph>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <p {...props} className={`card-text ${utilities}`} ref={ref}>
        {children}
      </p>
    );
  }
);

export const CardGroup = forwardRef<HTMLDivElement, BSDiv>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <div {...props} className={`card-group ${utilities}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export const CardLink = forwardRef<HTMLAnchorElement, BSAnchor>(
  ({ children, utilities = "", ...props }, ref) => {
    return (
      <a {...props} className={`card-link ${utilities}`} ref={ref}>
        {children}
      </a>
    );
  }
);

interface CardImageProps extends BSImg {
  orientation?: "top";
}

export const CardImage = forwardRef<HTMLImageElement, CardImageProps>(
  ({ orientation, utilities = "", ...props }, ref) => {
    const className = orientation === "top" ? "card-image-top" : "card-image";
    return <img {...props} className={`${className} ${utilities}`} ref={ref} />;
  }
);
