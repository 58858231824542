import React, { forwardRef } from "react";
import { BSAnchor } from "../types";

export interface BadgeProps extends BSAnchor {
  variant?: "pill" | "rectangle";
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
}

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      children,
      variant = "rectangle",
      color = "primary",
      utilities = "",
      ...props
    },
    ref
  ) => {
    props = { ...props, className: `badge bg-${color} ${utilities}` };

    if (color === "light") {
      props.className += " text-dark";
    }

    if (variant === "pill") {
      props.className += " rounded-pill";
    }

    return (
      <span {...props} ref={ref}>
        {children}
      </span>
    );
  }
);
