import React, { forwardRef } from "react";
import { BSDiv } from "../types";

export const Progress = forwardRef<HTMLDivElement, BSDiv>(
  ({ utilities = "", children, ...props }, ref) => (
    <div className={`progress ${utilities}`} {...props} ref={ref}>
      {children}
    </div>
  )
);

export interface ProgressBarProps extends BSDiv {
  value?: number;
  variant?: "solid" | "stripe";
}

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    { utilities = "", variant = "solid", value = 0, children, ...props },
    ref
  ) => {
    if (variant === "stripe") {
      utilities += " progress-bar-striped progress-bar-animated";
    }

    return (
      <div
        {...props}
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ width: `${value}%` }}
        role="progressbar"
        className={`progress-bar ${utilities}`}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
