// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1CK7FHVwhiOnuCiZJwiPq1 {\n\twidth: 40px;\n\theight: 40px;\n\tmargin: 100px auto;\n\tbackground-color: #fff;\n\n\tborder-radius: 100%;\n\t-webkit-animation: _1hEQ2VzIkTiZ-9jZoLI3JC 1s infinite ease-in-out;\n\tanimation: _1hEQ2VzIkTiZ-9jZoLI3JC 1s infinite ease-in-out;\n}\n\n@-webkit-keyframes _1hEQ2VzIkTiZ-9jZoLI3JC {\n\t0% {\n\t\t-webkit-transform: scale(0);\n\t}\n\t100% {\n\t\t-webkit-transform: scale(1);\n\t\topacity: 0;\n\t}\n}\n\n@keyframes _1hEQ2VzIkTiZ-9jZoLI3JC {\n\t0% {\n\t\t-webkit-transform: scale(0);\n\t\ttransform: scale(0);\n\t}\n\t100% {\n\t\t-webkit-transform: scale(1);\n\t\ttransform: scale(1);\n\t\topacity: 0;\n\t}\n}\n", ""]);
// Exports
exports.locals = {
	"spinner": "_1CK7FHVwhiOnuCiZJwiPq1",
	"sk-scaleout": "_1hEQ2VzIkTiZ-9jZoLI3JC"
};
module.exports = exports;
